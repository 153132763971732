define(['app',
  'productPrice',
  'productTags',
  'productVariations',
  'productQuantityInput',
  'productAddToBasket',
  'productAddToBasketButton',
  'productStockInformation',
  'productViewMoreInformation',
  'subscriptionComponent',
  'productName',
  'accessibleModalHelper',
  'siteObj',
  'productQuickbuySimple'
], function (app,
             productPrice,
             productTags,
             productVariations,
             productQuantityInput,
             productAddToBasket,
             productAddToBasketButton,
             productStockInformation,
             productViewMoreInformation,
             subscriptionComponent,
             productName,
             accessibleModalHelper,
             siteObj,
             productQuickbuySimple
             ) {

  let created = false;
  const emptyComponent = {
    init: () => {
    },
  };

  var productQuickbuy = function () {
    var component = {};

    var _config = {
      selectors: {
        loading: '[data-loading]',
        error: '[data-error]',
        openTrigger: '[data-open-product-quickbuy]',
        closeModalButton: '[data-close]',
        gallery: '[data-gallery]',
        quickbuyTarget: 'data-target-quickbuy'
      },
      classes: {
        showLoading: 'productQuickbuy_loading-show',
        showError: 'productQuickbuy_error-show',
        showQuickbuy: 'productQuickbuy-show',
      },
      subscribedChannelsToClear: {
        image: 'productSingleImage/newImage',
        price: 'productPrice/newPrice',
        tags: 'productTags/new',
        stock: 'productStockInformation/newStockInformation',
        addToBasketButton: 'productAddToBasketButton/newButton',
        information: 'productViewMoreInformation/newUrl',
        productName: 'productName/update',
        fastTrack: 'fastTrackSwitch/stateChange',
        subscribeAndSaveContracts: 'subscribeAndSaveContracts/update',
        subscribeAndSaveContractsContractIdChange: 'subscribeAndSaveContracts/changeContractId',
        subscribeAndSaveDefault: 'subscribeAndSave/subscribeDefault'
      },
      productAttribute: '[data-child-id]',
      productSku: 'data-child-id',
      productBlockSku: 'data-sku',
      recommendationContext: 'data-context',
      recommendationParentAttribute: '[data-component=productRecommendations]',
      fromWishlist: 'data-from-wishlist',
      showSubscribeAndSave: 'data-subscribe-and-save',
      productQuickbuySimple: '[data-component=productQuickbuySimple]'
    };

    var _init = function (element, userDriven) {
      component.element = element;
      component.loading = component.element.querySelector(component.config.selectors.loading);
      component.error = component.element.querySelector(component.config.selectors.error);
      component.closeModalButton = component.element.querySelector(component.config.selectors.closeModalButton);
      component.gallery = document.querySelector(component.config.selectors.gallery);
      //component.pageCategory = window.dataLayer[0].pageCategory;
      component.isProductPage = siteObj.type === 'product';

      if (component.element.querySelector(component.config.productAttribute)) {
        component.productId = component.element.querySelector(component.config.productAttribute).getAttribute(component.config.productSku);
      }

      if (!userDriven) {
        component.subscribe();
        component.loadDependencies();
      }

      component.context = component.getRecommendationContext();
      component.bindOpenTrigger();
      component.bindCloseTrigger();

      if(siteObj.config.enableQubit === true) {
        siteObj.methods = siteObj.methods || {};
        siteObj.methods.qubitReinitialiseQuickbuy = _qubitReinitialiseQuickbuy;
      }


      return component;
    };

    var _qubitReinitialiseQuickbuy = (selector) => {
      const qubitDOM = document.querySelector(selector);
      if (qubitDOM) {
        const newQuickbuysButtons = qubitDOM.querySelectorAll(component.config.selectors.openTrigger);
        const newQuickbuysSimple = qubitDOM.querySelectorAll(component.config.productQuickbuySimple);
        newQuickbuysSimple && newQuickbuysSimple.forEach(newQuickbuySimple => new productQuickbuySimple().init(newQuickbuySimple));
        newQuickbuysButtons && newQuickbuysButtons.forEach(newQuickbuyButton => newQuickbuyButton.addEventListener('click', component.loadModal));
      }
    }

    var _subscribe = function () {
      app.subscribe('productQuickbuy/startLoading', component.startLoading);
      app.subscribe('productQuickbuy/stopLoading', component.stopLoading);
      app.subscribe('productQuickbuy/showError', component.showError);
      app.subscribe('productQuickbuy/hideError', component.hideError);
      app.subscribe('productQuickbuy/hideModal', component.hideModal);
      app.subscribe('productQuickbuy/reinitialise', component.reinitialiseComponent);
    };

    var _bindOpenTrigger = function () {
      component.openTriggers = document.querySelectorAll(component.config.selectors.openTrigger);
      for (var i = 0, l = component.openTriggers.length; i < l; i++) {
        component.openTriggers[i].removeEventListener('click', component.loadModal);
        component.openTriggers[i].addEventListener('click', component.loadModal);
      }

      if (component.closeModalButton) {
        component.closeModalButton.removeEventListener('click', component.hideModal);
        component.closeModalButton.addEventListener('click', component.hideModal);
      }
    };

    const _bindCloseTrigger = () => {
      if (component.closeModalButton) {
        component.closeModalButton.removeEventListener('click', component.hideModal);
        component.closeModalButton.addEventListener('click', component.hideModal);
      }
      component.element.addEventListener('click', component.overlayClickHandler);
    };

    var _loadModal = function (event) {
      event.preventDefault();

      component.element = event.currentTarget.previousElementSibling;

      component.removeQuickbuyTarget();

      app.publish('tracking/record', 'Product | quickBuy', 'clicked', 'quickBuy button');
      app.publish('columbo/track', 'quick_buy_button', 'click');

      var productId = app.element.getAttribute(component.config.productBlockSku, event.target);
      var fromWishlist = app.element.getAttribute(component.config.fromWishlist, event.target);
      var showSubscribeAndSave = app.element.getAttribute(component.config.showSubscribeAndSave, event.target);
      var url = '/' + productId + '.quickbuy';
      const queryParams = [];
      if (component.gallery) {
        queryParams.push('variationSelect=true');
      }
      if (component.context) {
        queryParams.push('rctxt=' + component.context);
      }

      if (fromWishlist) {
        queryParams.push('fromWishlist=' + fromWishlist);
      }

      if (showSubscribeAndSave) {
        queryParams.push('enableSubscribeAndSave=' + showSubscribeAndSave);
        queryParams.push('variationSelect=' + showSubscribeAndSave);
      }

      if (new URLSearchParams(window.location.search).get('variationsUseImageSwatches') === 'true') {
        queryParams.push('variationsUseImageSwatches=true');
      }

      if (component.element.baseURI.includes('worthByPrice') || component.element.baseURI.includes('noWorth')) {
        queryParams.push('worthValue=worthByPrice');
      }

      queryParams.push('targetQuickbuy=true');

      const queryString = queryParams.join('&');
      if (queryString) {
        url += '?' + queryString;
      }

      app.ajax.get({
        url: url,
        dataType: 'JSON',
        error: component.showError,
        success: component.attachModal,
      });
    };

    var _attachModal = function (response) {
      component.element.outerHTML = response;
      component.initialiseAllComponents();
    };

    var _reinitialiseComponent = function () {
      var newProductQuickbuyObject = document.querySelector(`[${component.config.selectors.quickbuyTarget}]`) ? document.querySelector(`[${component.config.selectors.quickbuyTarget}]`) : document.querySelector('[data-component=productQuickbuy]');
      component.init(newProductQuickbuyObject, true);
    };

    var _initialiseAllComponents = function () {
      //productQuickbuy
      var newProductQuickbuy = document.querySelector(`[${component.config.selectors.quickbuyTarget}]`) ? document.querySelector(`[${component.config.selectors.quickbuyTarget}]`) : document.querySelector('[data-component=productQuickbuy]');
      component.init(newProductQuickbuy, true);

      //productSingleImage
      var newProductSingleImage = newProductQuickbuy.querySelector('[data-component=productSingleImage]');
      if (!component.isProductPage) {
        component.productSingleImage
        && new component.productSingleImage.init(newProductSingleImage);
      }

      //productPrice
      var newProductPrice = newProductQuickbuy.querySelector('[data-component=productPrice]');
      new productPrice().init(newProductPrice);

      // fastTrackSwitch
      var newFastTrackSwitch = newProductQuickbuy.querySelector('[data-component=fastTrackSwitch]');
      if (newFastTrackSwitch) {
        component.fastTrackSwitch
        && new component.fastTrackSwitch.init(newFastTrackSwitch);
      }
      // productTags
      var newProductTags = newProductQuickbuy.querySelector('[data-component=productTags]');
      if (newProductTags) {
        new productTags().init(newProductTags);
      }

      //productQuantityInput
      var newProductQuantityInput = newProductQuickbuy.querySelector('[data-component=productQuantityInput]');
      new productQuantityInput().init(newProductQuantityInput);

      //productVariations
      var newProductVariations = newProductQuickbuy.querySelector('[data-component=productVariations]');
      if (newProductVariations) {
        new productVariations().init(newProductVariations);
      }

      //productAddToBasket
      var newProductAddToBasket = newProductQuickbuy.querySelector('[data-component=productAddToBasket]');
      new productAddToBasket().init(newProductAddToBasket);

      //productAddToBasketButton
      var newProductAddToBasketButton = newProductQuickbuy.querySelector('[data-component=productAddToBasketButton]');
      if (!component.isProductPage) {
        new productAddToBasketButton().init(newProductAddToBasketButton);
      }

      // productName
      var newProductName = newProductQuickbuy.querySelector('[data-component=productName]');
      if (newProductName) {
        new productName().init(newProductName);
      }

      //productStockInformation
      var newProductStockInformation = newProductQuickbuy.querySelector('[data-component=productStockInformation]');
      if (!component.isProductPage) {
        new productStockInformation().init(newProductStockInformation);
      }

      //productViewMoreInformation
      var newProductViewMoreInformation = newProductQuickbuy.querySelector('[data-component=productViewMoreInformation]');
      new productViewMoreInformation().init(newProductViewMoreInformation);

      //subscriptionComponent
      var newProductSubscriptionComponent = newProductQuickbuy.querySelector('[data-component=subscriptionComponent]');
      if (newProductSubscriptionComponent) {
        new subscriptionComponent().init(newProductSubscriptionComponent);
      }

      // subscribeAndSave
      var newSubscribeAndSave = newProductQuickbuy.querySelector('[data-component=subscribeAndSave]');
      if (newSubscribeAndSave) {
        component.subscribeAndSave
        && new component.subscribeAndSave.init(newSubscribeAndSave);
      }

      // subscribeAndSaveContracts
      var newSubscribeAndSaveContracts = newProductQuickbuy.querySelector('[data-component=subscribeAndSaveContracts]');
      if (newSubscribeAndSaveContracts) {
        component.subscribeAndSaveContracts
        && new component.subscribeAndSaveContracts.init(newSubscribeAndSaveContracts);
      }

      // subscribeAndSaveProductInBasket
      var newSubscribeAndSaveProductInBasket = newProductQuickbuy.querySelector('[data-component=subscribeAndSaveProductInBasket]');
      if (newSubscribeAndSaveProductInBasket) {
        component.subscribeAndSaveProductInBasket
        && new component.subscribeAndSaveProductInBasket.init(newSubscribeAndSaveProductInBasket);
      }

      app.publish(component.config.subscribedChannelsToClear.subscribeAndSaveDefault);

      component.showModal(newProductQuickbuy);
    };

    var _showModal = function (modalInstance) {
      if (modalInstance) {
        app.element.addClass(component.config.classes.showQuickbuy, modalInstance);
      } else {
        app.element.addClass(component.config.classes.showQuickbuy, component.element);
      }

      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = 'fixed';
      document.body.style.width = '100vw';

      component.attachAccessibleModalHelper();

      app.publish('tracking/record', 'Product | quickBuy', 'viewed', 'quickBuy modal');
    };

    var _attachAccessibleModalHelper = function () {
      component.accessibleModalHelper = new accessibleModalHelper(
        document.querySelector('[data-component=productQuickbuy]'),
        component.hideModal,
        document.querySelector('.productQuickbuy_title'),
      );
    };

    var _hideModal = function () {
      for (var channel in component.config.subscribedChannelsToClear) {
        if (component.config.subscribedChannelsToClear.hasOwnProperty(channel) && !component.isProductPage) {
          app.clear(component.config.subscribedChannelsToClear[channel]);
        }
      }

      if (document.body.style.position === 'fixed') {
        const prevScrollTop = -1 * parseFloat(document.body.style.top);
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, prevScrollTop);
        document.body.style.width = '';
      }

      app.element.removeClass(component.config.classes.showQuickbuy, component.element);

      if (!component.isProductPage) {
        component.accessibleModalHelper && component.accessibleModalHelper.close();
      }

      app.publish('tracking/record', 'Product | quickBuy', 'clicked', 'close modal');
    };

    var _showError = function () {
      app.element.addClass(component.config.classes.showError, component.error);
    };

    var _hideError = function () {
      if (component.error) {
        app.element.removeClass(component.config.classes.showError, component.error);
      }
    };

    var _startLoading = function () {
      if (component.loading) {
        app.element.addClass(component.config.classes.showLoading, component.loading);
      }
    };

    var _stopLoading = function () {
      if (component.loading) {
        app.element.removeClass(component.config.classes.showLoading, component.loading);
      }
    };

    var _tracking = function () {
      app.publish('tracking/record', 'Product | quickBuy', 'viewed', component.productId);
    };

    var _getRecommendationContext = function () {
      // polyfill for IE8+
      if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.msMatchesSelector ||
          Element.prototype.webkitMatchesSelector;
      }

      if (!Element.prototype.closest) {
        Element.prototype.closest = function (s) {
          var el = this;
          if (!document.documentElement.contains(el)) return null;
          do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
          } while (el !== null && el.nodeType === 1);
          return null;
        };
      }

      const recommendationParent = component.element.closest(component.config.recommendationParentAttribute);
      return (recommendationParent) ? recommendationParent.getAttribute(component.config.recommendationContext) : '';
    };

    var _overlayClickHandler = function (event) {
      if (event.target === component.element) {
        event.preventDefault();
        component.hideModal();
      }
    };

    const _loadDependencies = () => {
      if (!siteObj.config.useProductImageBlockComponent) {
        require(['productSingleImage'], function (productSingleImage) {
          component.productSingleImage = productSingleImage();
        });
      }

      if (siteObj.config.enableSubscribeAndSave && !siteObj.config.removeSubscribeAndSaveQuickbuy) {
        require(['subscribeAndSave'], function (subscribeAndSave) {
          component.subscribeAndSave = subscribeAndSave();
        });

        require(['subscribeAndSaveContracts'], function (subscribeAndSaveContracts) {
          component.subscribeAndSaveContracts = subscribeAndSaveContracts();
        });

        require(['subscribeAndSaveProductInBasket'], function (subscribeAndSaveProductInBasket) {
          component.subscribeAndSaveProductInBasket = subscribeAndSaveProductInBasket();
        });
      }

      if (siteObj.config.fastTrackEnabled) {
        require(['fastTrackSwitch'], function (fastTrackSwitch) {
          component.fastTrackSwitch = fastTrackSwitch();
        });
      }
    };

    var _removeQuickbuyTarget = function () {
      document.querySelectorAll('[data-component=productQuickbuy]').forEach(el => {
        el.removeAttribute(component.config.selectors.quickbuyTarget);
      });
    };

    component.init = _init;
    component.subscribe = _subscribe;
    component.reinitialiseComponent = _reinitialiseComponent;
    component.config = _config;
    component.bindOpenTrigger = _bindOpenTrigger;
    component.bindCloseTrigger = _bindCloseTrigger;
    component.initialiseAllComponents = _initialiseAllComponents;
    component.startLoading = _startLoading;
    component.stopLoading = _stopLoading;
    component.loadModal = _loadModal;
    component.attachModal = _attachModal;
    component.showModal = _showModal;
    component.attachAccessibleModalHelper = _attachAccessibleModalHelper;
    component.hideModal = _hideModal;
    component.showError = _showError;
    component.hideError = _hideError;
    component.tracking = _tracking;
    component.getRecommendationContext = _getRecommendationContext;
    component.overlayClickHandler = _overlayClickHandler;
    component.loadDependencies = _loadDependencies;
    component.removeQuickbuyTarget = _removeQuickbuyTarget;

    return component;
  };

  return function () {
    if (created) return emptyComponent;
    created = true;
    return new productQuickbuy();
  };
});
